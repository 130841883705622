<template>
  <Inlogscherm />
</template>

<script>
import Inlogscherm from "@/components/login/Inlogscherm";
export default {
  data() {
    return {
      data: [],
    };
  },
  components: {
    Inlogscherm,
  },
  metaInfo: {
    title: "Welkom bij K-env| Dashboard",
    htmlAttrs: {
      lang: "EN",
      amp: true,
    },
  },
};
</script>
